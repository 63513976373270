import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const ServiceSidebar = () => {
  const query = graphql`
    query {
      allContentfulServiceCategory(sort: { fields: createdAt }) {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
    }
  `
  const data = useStaticQuery(query)
  const services = data.allContentfulServiceCategory.edges

  return (
    <>
      {services.map(service => {
        // Normalize the slug to have single /services/ prefix
        const formattedSlug = service.node.slug.startsWith("/")
          ? service.node.slug
          : `/${service.node.slug}`

        // Remove any double /services/ if present
        const finalSlug = formattedSlug.replace(
          /\/services\/services\//,
          "/services/"
        )

        return (
          <li key={service.node.id} id={service.node.id}>
            <Link to={finalSlug}>{service.node.name}</Link>
          </li>
        )
      })}
    </>
  )
}

export default ServiceSidebar
