import React from "react"
import { Link } from "gatsby"

const ServiceTypesSideBar = ({ data }) => {
  const serviceTypes = data.allContentfulSingleService.edges

  return (
    <>
      {serviceTypes.map((serviceType, index) => {
        // Normalize the slug to have single /services/ prefix
        const formattedSlug = serviceType.node.slug.startsWith("/")
          ? serviceType.node.slug
          : `/${serviceType.node.slug}`

        // Remove any double /services/ if present
        const finalSlug = formattedSlug.replace(
          /\/services\/services\//,
          "/services/"
        )

        return (
          <li key={serviceType.node.id}>
            <Link to={finalSlug}>{serviceType.node.name}</Link>
          </li>
        )
      })}
    </>
  )
}

export default ServiceTypesSideBar
